//
//
//
//
//
//

export default {
    components: {

    },
    name: 'Customer',
}
